import React, { useState, useMemo, useEffect } from "react";

//routing
import { useParams } from "react-router-dom";

import "./index.scss";
import "../../scss/main.scss";

import { loadPhotosJson, loadLenguage, photoFilter, getUniSerialNumber } from "../../utilities";

import Header from "../../components/Header";
import Masonry from "../../components/Masonry";
import Breadcrumb from "../../components/Breadcrumb";
import DynamicText from "../../components/DynamicText";
import Input from "../../components/Input";
import Icon from "../../components/Icon";
import Footer from "../../components/Footer";

const Photos = (props) => {
	const { params } = useParams();

	const [photos, getPhotos] = useState([]);
	const [photosFilteredBySearch, setPhotosFilteredBySearch] = useState([]);
	const [jsonPhotos, getJsonPhotos] = useState([]);
	const [splittedParams, setSplittedParams] = useState([]);
	const [titlePage, setTitlePage] = useState();
	const [subtitlePage, setSubtitlePage] = useState([]);
	const [oldParams, setOldParams] = useState(params);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useMemo(() => {
		// window.scrollTo(0, 0);

		getPhotos([]);
		setSplittedParams([]);
		getJsonPhotos(loadPhotosJson());
		setSubtitlePage([]);

		loadLenguage();

		setOldParams(window.location.href.substring(window.location.href.indexOf("photos/") + 7));

		//imposto il nome della pagina correttamente
		document.title = process.env.REACT_APP_APP_NAME_ABR;

		//splitto tutte le coppie dei parametri
		let splittedParams = params.split("&");

		//se non c'è il parametro "title" lo aggiungo io
		if (!params.includes("title")) {
			splittedParams.push("title=BRi_foto");
		}

		splittedParams.forEach((coupleValues) => {
			const singleParam = coupleValues.split("=");

			if (!(singleParam[0] === "title")) {
				setSplittedParams((oldValues) => [...oldValues, { [singleParam[0]]: singleParam[1] }]);
				setSubtitlePage((oldVal) => [...oldVal, singleParam[1]]);
			} else {
				setTitlePage(singleParam[1].replaceAll("-", " ").replaceAll("citta", "città").replaceAll("dorcia", "d'orcia"));
				//imposto il nome della pagina correttamente
				document.title = process.env.REACT_APP_APP_NAME_ABR + " - " + singleParam[1].replaceAll("-", " ");
			}
		});
	}, []);

	const splitParamsFunction = () => {
		let filteredPhotos = [];

		//filtro prima i filtri semplici
		splittedParams.map((param, i) => {
			if (Object.values(param)[0].indexOf("-") === -1) {
				filteredPhotos = photoFilter(
					i === 0 ? jsonPhotos.foto : filteredPhotos,
					Object.keys(param)[0],
					Object.values(param)[0]
				);
			}
		});

		splittedParams.map((param, i) => {
			if (Object.values(param)[0].indexOf("-") !== -1) {
				let multipleValues = Object.values(param)[0].split("-");
				filteredPhotos = photoFilter(
					i === 0 ? jsonPhotos.foto : filteredPhotos,
					Object.keys(param)[0],
					"",
					multipleValues
				);
			}
		});

		//filtro i valori unici
		filteredPhotos = [...new Set(filteredPhotos)];
		getPhotos(filteredPhotos);
		setPhotosFilteredBySearch(filteredPhotos);
	};

	//quando cambiano i parametri dell'url faccio il controllo con i parametri appena cambiati e con i parametri vecchi
	//se sono diverso faccio il reload della pagina
	useEffect(() => {
		if (params !== oldParams) {
			window.location.reload();
		}

		//se sono cambiati
		//creo la funzione che splitta i parametri e la richiamo, così mi si refreshano i componenti senza il reload
	}, [params, oldParams, splittedParams]);

	useMemo(() => {
		if (splittedParams.length > 0) {
			splitParamsFunction();
		}
	}, [splittedParams]);

	const searchFunction = (stringSearched) => {
		let arrayFiltered = [];

		photos.map((photoData) => {
			let isTrue = false;
			if (!isTrue && stringSearched.toLowerCase().trim() !== "") {
				for (const [key, value] of Object.entries(photoData)) {
					if (isTrue) continue;
					if (typeof value !== "number") {
						if (value.includes(stringSearched.toLowerCase())) {
							arrayFiltered.push(photoData);
							isTrue = true;
						}
					} else {
						if (value === stringSearched.toLowerCase()) {
							arrayFiltered.push(photoData);
							isTrue = true;
						}
					}
				}
			}
		});

		if (stringSearched.trim() === "") setPhotosFilteredBySearch(photos);
		else setPhotosFilteredBySearch(arrayFiltered);

		//pulisco la ricerca
		document.querySelectorAll(".search-input").forEach((input) => {
			input.placeholder = `searched: ${stringSearched}`;
			if (input.value === "") input.placeholder = "search";
			input.value = "";
		});
	};

	return (
		<React.Fragment>
			<Header search={true} fnToexecute={searchFunction} />
			<main>
				{photosFilteredBySearch?.length > 0 ? (
					<div className="container">
						<div className="pages-title">
							<div className="pages-title__title font-title">
								<DynamicText text={titlePage.replace("_", " ")} />
							</div>
							<div className="pages-title__subtitle font-title">
								<Breadcrumb path={["categorie", titlePage.replace("_", " ")]} />
							</div>
						</div>

						<div className="section section_masonry">
							<Masonry photos={photosFilteredBySearch} key={getUniSerialNumber()} />
						</div>
					</div>
				) : (
					<div className="container empty-photos">
						<h2>OOPS! Sembra che la ricerca non abbia mostrato risultati...</h2>
						<div className="empty-photos__subtitle">
							Prova con altre parole
							<span>
								<Input isDark={true} fnToexecute={searchFunction} placeholder="nuova ricerca" />
							</span>
						</div>
					</div>
				)}
			</main>
			<div className="div-search">
				<Input isDark={true} fnToexecute={searchFunction} placeholder="cerca nella pagina" />
			</div>
			<Footer />
		</React.Fragment>
	);
};

export default Photos;
