import React, { useState, useMemo, useEffect } from "react";

import "./index.scss";
import "../../scss/main.scss";

import { loadPhotosJson, loadLenguage, getUniSerialNumber } from "../../utilities";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Tab from "../../components/Tab";
import DynamicText from "../../components/DynamicText";
import Middle from "../../components/Cards/Middle";

const Category = (props) => {
	// const { category } = useParams();

	const [photos, getPhotos] = useState([]);
	const [filterOnClick, setFilterOnClick] = useState("tutte");
	const [states, getStates] = useState([]);
	const [statesImg, getStatesImg] = useState([]);
	const [cities, getCities] = useState([]);
	const [citiesImg, getCitiesImg] = useState([]);

	const [citiesCards, setCitiesCards] = useState([]);
	const [statesCards, setStatesCards] = useState([]);

	const [tabName, setTabName] = useState([
		"tutte",
		"natura",
		"paesaggi",
		"street & persone",
		"varie",
		"città",
		"stati",
	]);

	useMemo(() => {
		window.scrollTo(0, 0);

		//recupero le lingue
		const lenguagesJSON = loadLenguage();

		//setto il nome della pagina
		document.title = `${process.env.REACT_APP_APP_NAME_ABR} - Categorie`;

		const arrayPhoto = loadPhotosJson();

		getPhotos(arrayPhoto);

		let states_local = window.sessionStorage.getItem("all_states").split(",").sort();
		let cities_local = window.sessionStorage.getItem("all_cities").split(",").sort();

		const localStateImg = states_local.map((state, i) => {
			return arrayPhoto.foto.find((photo) => {
				if (photo.state === state) return photo;
			});
		});
		const localCitiesImg = cities_local.map((city, i) => {
			return arrayPhoto.foto.find((photo) => {
				if (photo.city === city) return photo;
			});
		});

		getStatesImg(localStateImg);
		getCitiesImg(localCitiesImg);

		states_local = states_local.map((state) => {
			return state.replace("_", " ");
		});

		getStates(states_local);
		getCities(cities_local);

		//in base alla lingua cambio i tab || scorro le chiavi delle lingue
		Object.keys(lenguagesJSON).map((leng) => {
			//se la lingua coincide con quella salvata nel session storage
			if (leng === sessionStorage.getItem("leng")) {
				const newTabName = [];
				tabName.map((name) => {
					if (lenguagesJSON.it.indexOf(name.toLowerCase()) !== -1) {
						return newTabName.push(lenguagesJSON[leng][lenguagesJSON.it.indexOf(name.toLowerCase())]);
					} else {
						return newTabName.push(name);
					}
				});

				setTabName(newTabName);
			}
		});
	}, []);

	useEffect(() => {
		Object.values(document.querySelectorAll("[data-card]")).forEach((child) => {
			//se è diverso il data-card dall'elemento selezionato lo aggiungo, altrimenti lo rimuovo
			if (
				filterOnClick === "tutte" ||
				filterOnClick === "all" ||
				filterOnClick === "todas" ||
				child.getAttribute("data-card").toLowerCase() === filterOnClick
			) {
				if (child.classList.contains("hidden")) child.classList.remove("hidden");
			} else {
				if (!child.classList.contains("hidden")) child.classList.add("hidden");
			}
		});
	}, [filterOnClick]);

	//usato questo metodo perchè altrimenti con il document.querySelector non riuscivo ad usare i data-card su città e stati
	useMemo(() => {
		setCitiesCards([]);
		cities.map((city, i) => {
			return setCitiesCards((oldValue) => [
				...oldValue,
				<Middle
					background={require(`../../images/photos/${citiesImg[i].url}.jpg`)}
					title={city}
					subtitle={citiesImg[i].state}
					metaData={tabName[5]}
					link={`/${process.env.REACT_APP_ROUTE_PHOTO_BASE}city=${city.replace(" ", "_")}&title=${city.replace(
						" ",
						"_"
					)}`}
					key={getUniSerialNumber()}
				/>,
			]);
		});
	}, [cities]);

	//usato questo metodo perchè altrimenti con il document.querySelector non riuscivo ad usare i data-card su città e stati
	useMemo(() => {
		setStatesCards([]);
		states.map((state, i) => {
			return setStatesCards((oldValue) => [
				...oldValue,
				<Middle
					background={require(`../../images/photos/${statesImg[i].url}.jpg`)}
					title={state}
					metaData={tabName[6]}
					link={`/${process.env.REACT_APP_ROUTE_PHOTO_BASE}state=${state.replace(" ", "_")}&title=${state.replace(
						" ",
						"_"
					)}`}
					key={getUniSerialNumber()}
				/>,
			]);
		});
	}, [states]);

	return (
		<React.Fragment>
			<Header />
			<main>
				<div className="container main_category">
					<div className="pages-title">
						<div className="pages-title__title font-title">
							<DynamicText text={"Categorie"} />
						</div>
					</div>
					<Tab
						tabs={["tutte", "natura", "paesaggi", "street & persone", "varie", "città", "stati"]}
						fnOnclick={(el) => {
							setFilterOnClick(el.target.innerText.toLowerCase());
						}}
					/>

					<div className="main_category__folders">
						<div className="folder-section" data-card={tabName[1]}>
							{filterOnClick === "tutte" ? (
								<div className="folder-section__title">
									<DynamicText text={tabName[1]} />
								</div>
							) : null}

							<div className="folder-section__folder">
								<Middle
									background={require("../../images/photos/2022_it_animale4_pavia.jpg")}
									title={`Mondo naturale`}
									subtitle={`Tutto sul mondo della natura`}
									metaData={tabName[1]}
									link={`/${process.env.REACT_APP_ROUTE_PHOTO_NATURA}`}
								/>
								<Middle
									background={require("../../images/photos/2021_it_parcoFaunistisco4_bergamo.jpg")}
									title={`Animali`}
									subtitle={`osserva gli animali`}
									metaData={tabName[1]}
									link={`/${process.env.REACT_APP_ROUTE_PHOTO_ANIMALI}`}
								/>
								<Middle
									background={require("../../images/photos/2023_it_stagnoOrtoBotanico_pavia.jpg")}
									title={`Vegetazione`}
									subtitle={`sbircia tra le foglie`}
									metaData={tabName[1]}
									link={`/${process.env.REACT_APP_ROUTE_PHOTO_VEGETALI}`}
								/>
							</div>
						</div>
						<div className="folder-section" data-card={tabName[2]}>
							{filterOnClick === "tutte" ? (
								<div className="folder-section__title">
									<DynamicText text={tabName[2]} />
								</div>
							) : null}

							<div className="folder-section__folder">
								<Middle
									background={require("../../images/photos/2023_at_innsbruck3_innsbruck.jpg")}
									title={`Tutti i paesaggi`}
									subtitle={`lascia scorrere lo sguardo`}
									metaData={tabName[2]}
									link={`/${process.env.REACT_APP_ROUTE_PHOTO_PAESAGGI}`}
								/>
								<Middle
									background={require("../../images/photos/2019_it_gromo1_bergamo.jpg")}
									title={`Montagne`}
									subtitle={`guarda l'orizzonte`}
									metaData={tabName[2]}
									link={`/${process.env.REACT_APP_ROUTE_PHOTO_MONTAGNE}`}
								/>
								<Middle
									background={require("../../images/photos/2024_it_panorama8_torino.jpg")}
									title={`Fiumi`}
									subtitle={`segui l'acqua`}
									metaData={tabName[2]}
									link={`/${process.env.REACT_APP_ROUTE_PHOTO_FIUMI}`}
								/>
								<Middle
									background={require("../../images/photos/2022_it_workshop3_venezia.jpg")}
									title={`Mare`}
									subtitle={`insegui le onde`}
									metaData={tabName[2]}
									link={`/${process.env.REACT_APP_ROUTE_PHOTO_MARE}`}
								/>
								<Middle
									background={require("../../images/photos/2022_it_lago1_sirmione.jpg")}
									title={`Laghi`}
									subtitle={`scopri i laghi`}
									metaData={tabName[2]}
									link={`/${process.env.REACT_APP_ROUTE_PHOTO_LAGO}`}
								/>
							</div>
						</div>
						<div className="folder-section" data-card={tabName[3]}>
							{filterOnClick === "tutte" ? (
								<div className="folder-section__title">
									<DynamicText text={tabName[3]} />
								</div>
							) : null}
							<div className="folder-section__folder">
								<Middle
									background={require("../../images/photos/2018_it_ritrattoCampagnaBF3_lodi.jpg")}
									title={`Street & Persone`}
									subtitle={`fatti raccontare una storia`}
									metaData={tabName[3]}
									link={`/${process.env.REACT_APP_ROUTE_PHOTO_PERSONE}`}
								/>
								<Middle
									background={require("../../images/photos/2022_it_ritrattoRagazza_bergamo.jpg")}
									title={`Ritratti`}
									subtitle={`lasciati inspirare`}
									metaData={tabName[3]}
									link={`/${process.env.REACT_APP_ROUTE_PHOTO_RITRATTI}`}
								/>
							</div>
						</div>
						<div className="folder-section" data-card={tabName[4]}>
							{filterOnClick === "tutte" ? (
								<div className="folder-section__title">
									<DynamicText text={tabName[4]} />
								</div>
							) : null}
							<div className="folder-section__folder">
								<Middle
									background={require("../../images/photos/2023_it_barcacciaDettaglio_roma.jpg")}
									title={`Varie`}
									subtitle={`in ogni dettaglio`}
									metaData={tabName[4]}
									link={`/${process.env.REACT_APP_ROUTE_PHOTO_VARIE}`}
								/>
								<Middle
									background={require("../../images/photos/2024_it_statua2_firenze.jpg")}
									title={`Statue`}
									subtitle={`uno sguardo al passato`}
									metaData={tabName[4]}
									link={`/${process.env.REACT_APP_ROUTE_PHOTO_STATUE}`}
								/>
							</div>
						</div>
						<div className="folder-section" data-card={tabName[5]}>
							{filterOnClick === "tutte" ? (
								<div className="folder-section__title">
									<DynamicText text={tabName[5]} />
								</div>
							) : null}

							<div className="folder-section__folder">
								{/* {cities?.length > 0 && citiesImg?.length > 0
									? cities.map((city, i) => {
											return (
												<Middle
													background={require(`../../images/photos/${citiesImg[i].url}.jpg`)}
													title={city}
													subtitle={citiesImg[i].state}
													metaData={tabName[5]}
													link={`/${process.env.REACT_APP_ROUTE_PHOTO_BASE}city=${city.replace(
														" ",
														"_"
													)}&title=${city.replace(" ", "_")}`}
													key={getUniSerialNumber()}
												/>
											);
									  })
									: null} */}
								{citiesCards}
							</div>
						</div>
						<div className="folder-section" data-card={tabName[6]}>
							{filterOnClick === "tutte" ? (
								<div className="folder-section__title">
									<DynamicText text={tabName[6]} />
								</div>
							) : null}
							<div className="folder-section__folder">
								{/* {states?.length > 0 && statesImg?.length > 0
									? states.map((state, i) => {
											return (
												<Middle
													background={require(`../../images/photos/${statesImg[i].url}.jpg`)}
													title={state}
													metaData={tabName[6]}
													link={`/${process.env.REACT_APP_ROUTE_PHOTO_BASE}state=${state.replace(
														" ",
														"_"
													)}&title=${state.replace(" ", "_")}`}
													key={getUniSerialNumber()}
												/>
											);
									  })
									: null} */}
								{statesCards}
							</div>
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</React.Fragment>
	);
};

export default Category;
