import React from "react";

import "./index.scss";
import Logo from "../Logo";

const Footer = (props) => {
	return (
		<React.Fragment>
			<footer id="footer">
				<div className="footer__column"></div>
				<div className="footer__column" id="f-col2">
					<Logo />
					<span>© Riccardo Biffi {new Date().getFullYear()}</span>
				</div>
				<div className="footer__column"></div>
			</footer>
		</React.Fragment>
	);
};

export default Footer;
