import React from "react";

import "./index.scss";

const Info = ({ photo }) => {
	return (
		<React.Fragment>
			<div className="card-info" data-cat={photo.category}>
				<div
					className="card-info__image"
					style={{ "--backgound": `url(${require(`../../../images/photos/${photo.url}.jpg`)})` }}
				></div>
				<div className="card-info__data">
					<div className={`data data__alt-category ${photo.category}`}>{photo.category}</div>
					<div className="data data__position">
						{photo.city}, {photo.state} - {photo.year}
					</div>
					<div className="data data__alt-image">{photo.altimage}</div>
					<div className="data data__tags">{photo.tags.join(", ")}</div>
					<div className="data data__url">{photo.url}</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Info;
