import React from "react";

import "./index.scss";

import { Link } from "react-router-dom";

import DynamicText from "../DynamicText";
import Leaf from "../../images/leaf.png";

const Button = (props) => {
	const { type, url = "", onclickFunction, text } = props;

	return (
		<React.Fragment>
			<Link
				className={`button ${type === "" ? "button--primary" : `button--${type}`}`}
				onClick={(e) => {
					if (onclickFunction && !url) {
						onclickFunction();
					}
				}}
				to={url && !onclickFunction ? url : null}
			>
				{type === "nat" ? (
					<span>
						<img src={Leaf} className="button-leaf" alt={"foglia"} loading="lazy" />
					</span>
				) : null}
				{type === "nat" ? (
					<span>
						<img src={Leaf} className="button-leaf" alt={"foglia"} loading="lazy" />
					</span>
				) : null}
				{type === "nat" ? (
					<span>
						<img src={Leaf} className="button-leaf" alt={"foglia"} loading="lazy" />
					</span>
				) : null}
				{type === "nat" ? (
					<span>
						{" "}
						<img src={Leaf} className="button-leaf" alt={"foglia"} loading="lazy" />
					</span>
				) : null}
				<DynamicText text={text} />
			</Link>
		</React.Fragment>
	);
};

export default Button;
