import React, { useState, useMemo } from "react";

import "./index.scss";

import { loadLenguage } from "../../utilities";

const DynamicText = ({ text, isCapitalize = false }) => {
	const [word, setWord] = useState("");

	useMemo(() => {
		//recupero le lingue
		const lenguagesJSON = loadLenguage();

		//scorro le chiavi delle lingue
		Object.keys(lenguagesJSON).map((leng) => {
			//se la lingua coincide con quella salvata nel session storage
			if (leng === sessionStorage.getItem("leng")) {
				//ricerco la parola inserita nell'italiano, se coincide mi salvo l'indice
				if (lenguagesJSON.it.indexOf(text.toLowerCase()) !== -1) {
					return setWord(lenguagesJSON[leng][lenguagesJSON.it.indexOf(text.toLowerCase())]);
				} else {
					return setWord(text);
				}
			}
		});
	}, []);

	return (
		<React.Fragment>
			{isCapitalize ? <>{word.substring(0, 1).toUpperCase() + word.substring(1)}</> : word}
		</React.Fragment>
	);
};

export default DynamicText;
