import React, { useEffect, useMemo, useState } from "react";

import "./index.scss";

import { loadLenguage } from "../../../utilities";

import DynamicText from "../../DynamicText";

//routing 
import { Link } from "react-router-dom";

const Middle = ({ background, title, subtitle, link, metaData }) => {
	const [word, setWord] = useState("");

	useEffect(() => {
		//recupero le lingue
		const lenguagesJSON = loadLenguage();

		//scorro le chiavi delle lingue
		Object.keys(lenguagesJSON).map((leng) => {
			//se la lingua coincide con quella salvata nel session storage
			if (leng === sessionStorage.getItem("leng")) {
				//ricerco la parola inserita nell'italiano, se coincide mi salvo l'indice
				if (lenguagesJSON.it.indexOf(metaData.toLowerCase()) !== -1) {
					return setWord(lenguagesJSON[leng][lenguagesJSON.it.indexOf(metaData.toLowerCase())]);
				} else {
					return setWord(metaData);
				}
			}
		});
	}, []);

	return (
		<React.Fragment>
			<Link to={link ? link : ""} data-card={word}>
				<div className="card_middle" style={background ? { "--backgound": `url(${background})` } : {}}>
					<div className="card_middle__titles">
						<div className="title">
							<DynamicText text={title} />
						</div>
						{subtitle ? (
							<div className="subtitle">
								<DynamicText text={subtitle.replaceAll("_", " ")} />
							</div>
						) : (
							""
						)}
					</div>
				</div>
			</Link>
		</React.Fragment>
	);
};

export default Middle;
