import React, { useState, useEffect, useMemo } from "react";

import "./index.scss";
import "../../scss/main.scss";

import { loadPhotosJson, getUniSerialNumber, loadLenguage } from "../../utilities";

import Header from "../../components/Header";
import Tab from "../../components/Tab";
import Input from "../../components/Input";
import Info from "../../components/Cards/Info";
import Footer from "../../components/Footer";

import imgPlaceHolder from "../../images/placeholder.png";

const AdminPanel = (props) => {
	const [jsonPhoto, setJsonPhoto] = useState([]);
	const [jsonPhotoFiltered, setJsonPhotoFiltered] = useState([]);
	const [reactImageUrlAdded, setReactImageUrlAdded] = useState("");
	const [nameStateAbr, getNameStateAbr] = useState("");
	const [reloadImg, setReloadImg] = useState(false);
	const [dataNameOfImage, setDataNameOfImage] = useState({
		year: "xxxx",
		state: "xx",
		altimage: "xxx",
		city: "xxxx",
		category: "not defined",
		photoName: "not defined",
		tags: [],
	});

	const [tableTitle, setTableTitle] = useState([]);
	const [viewType, setViewType] = useState("default");
	const [filterOnClick, setFilterOnClick] = useState("grid");

	useMemo(() => {
		window.scrollTo(0, 0);
		document.title = `Admin Panel`;

		loadLenguage();

		//recupero il json con tutte le foto
		(async () => {
			const jsonOrigin = loadPhotosJson(true);
			setJsonPhoto(jsonOrigin.foto);
			setJsonPhotoFiltered(jsonOrigin.foto);

			setTableTitle([]);
			Object.keys(jsonOrigin?.foto[0]).map((td) => {
				return setTableTitle((oldValue) => [...oldValue, td]);
			});
		})();
	}, []);

	const checkImage = (url) => {
		try {
			const check = require(`./../../images/photos/${url}.jpg`);
			return check;
		} catch (error) {
			// console.warn("Immagine non trovata");
			return false;
		}
	};

	const nameImage = (passedCompletedName) => {
		let stateFullName = "";
		switch (passedCompletedName.state) {
			case "it":
				stateFullName = "italia";
				break;
			case "pt":
				stateFullName = "portogallo";
				break;
			case "es":
				stateFullName = "spagna";
				break;
			case "cz":
				stateFullName = "repubblica ceca";
				break;
			case "al":
				stateFullName = "albania";
				break;
			case "fr":
				stateFullName = "francia";
				break;
			case "de":
				stateFullName = "germania";
				break;
			case "at":
				stateFullName = "austria";
				break;
			case "br":
				stateFullName = "brasile";
				break;
			case "va":
				stateFullName = "città del vaticano";
				break;
			case "hr":
				stateFullName = "croazia";
				break;
			case "gr":
				stateFullName = "grecia";
				break;
			case "mt":
				stateFullName = "malta";
				break;
			case "mx":
				stateFullName = "messico";
				break;
			case "gb":
				stateFullName = "gran bretagna";
				break;
			case "ro":
				stateFullName = "romania";
				break;
			case "si":
				stateFullName = "slovenia";
				break;
			case "us":
				stateFullName = "stati uniti";
				break;
			case "se":
				stateFullName = "svezia";
				break;
			case "tn":
				stateFullName = "tunisia";
				break;
			case "tr":
				stateFullName = "turchia";
				break;
			default:
				stateFullName = passedCompletedName.state;
				break;
		}

		//compongo il nome dell'url
		const completeName = `${passedCompletedName.year}_${nameStateAbr}_${
			passedCompletedName.altimage
		}_${passedCompletedName.city.toLowerCase()}`;

		//aggiorno la variabile con il nuovo valore
		passedCompletedName = { ...passedCompletedName, photoName: completeName };
		passedCompletedName = { ...passedCompletedName, state: stateFullName };

		const checkValue = checkImage(completeName);
		if (checkValue) {
			setReactImageUrlAdded(checkValue);
		} else {
			setReactImageUrlAdded("");
		}

		setDataNameOfImage({ ...passedCompletedName, url: completeName });

		return completeName;
	};

	const saveData = () => {
		jsonPhoto.push(dataNameOfImage);

		jsonPhoto.map((photo) => {
			return photo.tags.map((tag, i) => {
				// console.log(tag.trim());
				return (photo.tags[i] = tag.trim());
			});
		});

		jsonPhoto.map((row) => {
			if (row.photoName) delete row.photoName;
			return null;
		});

		// console.log(jsonPhoto);
	};

	const updateName = (element) => {
		//forzo il reload dell'immagine
		setReloadImg(!reloadImg);

		//recupero tutti i valori dell'array
		let getCompletedData = {};

		if (Object.keys(element)[0] === "tags")
			getCompletedData = {
				...dataNameOfImage,
				[Object.keys(element)[0]]: Object.values(element)[0].trim().split(","),
			};
		else getCompletedData = { ...dataNameOfImage, [Object.keys(element)[0]]: Object.values(element)[0] };

		//salvo i valori nuovi nella variabile
		setDataNameOfImage(getCompletedData);
		//compongo il nome finale della foto
		nameImage(getCompletedData);
	};

	const getData = () => {
		// console.log(JSON.stringify(jsonPhoto));

		document.getElementsByClassName("div-json-object")[0].childNodes[0].innerHTML = JSON.stringify(jsonPhoto);
		document.getElementsByClassName("div-json-object")[0].style.display = "block";

		return;
	};

	//carico il contenuto della tabella
	const loadTableContent = () => {
		return jsonPhotoFiltered.map((row) => {
			return (
				<tr key={`${row["url"]}}`} data-cat={row.category}>
					<td>
						<img
							src={checkImage(row.url)}
							alt={row.altimage}
							key={getUniSerialNumber()}
							onError={() => {
								console.warn("Immagine non trovata");
							}}
						/>
					</td>
					{tableTitle.map((title) => {
						return Object.entries(row).map(([key, value]) => {
							return title === key ? (
								<td key={`${title}-${key}`}>
									{key === "tags"
										? value.sort().map((val, i) => {
												return i !== value.length - 1 ? val + ", " : val;
										  })
										: value}
								</td>
							) : (
								""
							);
						});
					})}
				</tr>
			);
		});
	};

	const loadCardsContent = () => {
		return jsonPhotoFiltered?.map((photo) => {
			return <Info photo={photo} key={getUniSerialNumber()} />;
		});
	};

	const loadSelectValue = () => {
		let values = [" "];
		jsonPhotoFiltered?.map((imageJson) => {
			if (!values.includes(imageJson.category)) values.push(imageJson.category);
			return;
		});

		return values.map((cat) => {
			return (
				<option value={cat} key={cat}>
					{cat}
				</option>
			);
		});
	};

	const searchFunction = (text) => {
		let arrayFiltered = [];

		jsonPhoto.map((photoData) => {
			let istrue = false;
			if (!istrue && text.trim() !== "") {
				for (const [key, value] of Object.entries(photoData)) {
					if (istrue) continue;
					if (typeof value !== "number") {
						if (value.includes(text)) {
							arrayFiltered.push(photoData);
							istrue = true;
						}
					} else {
						if (value === text) {
							arrayFiltered.push(photoData);
							istrue = true;
						}
					}
				}
			}
		});

		if (text.trim() === "") setJsonPhotoFiltered(jsonPhoto);
		else setJsonPhotoFiltered(arrayFiltered);
	};

	//2020_it_ramo_lodi
	return (
		<React.Fragment>
			<Header />
			<main className="admin-panel">
				<div className="container">
					<h2>Add Image</h2>
					<div className="card-set-image">
						<div className="set-image-data">
							<div className="image-data">
								<input
									className="input"
									type="number"
									onBlur={(el) => {
										updateName({ year: parseInt(el.currentTarget.value) });
									}}
									defaultValue=""
									placeholder="2024"
								/>
								<select
									name="category_select"
									onBlur={(el) => {
										updateName({ category: el.currentTarget.value });
									}}
								>
									{jsonPhoto ? loadSelectValue() : ""}
								</select>
								<input
									className="input"
									type="text"
									onBlur={(el) => {
										updateName({ state: el.currentTarget.value });
										getNameStateAbr(el.currentTarget.value);
									}}
									defaultValue=""
									placeholder="it"
								/>
								<input
									className="input"
									type="text"
									onBlur={(el) => {
										updateName({ city: el.currentTarget.value });
									}}
									defaultValue=""
									placeholder="lodi"
								/>

								<input
									className="input"
									type="text"
									onBlur={(el) => {
										updateName({ altimage: el.currentTarget.value });
									}}
									defaultValue=""
									placeholder="Descrive l'immagine"
								/>
								<textarea
									onBlur={(el) => {
										updateName({ tags: el.currentTarget.value });
									}}
									defaultValue=""
									placeholder="Inserisci i tags"
								/>
								<span id="inputNameImage">{dataNameOfImage.url}</span>

								<button onClick={saveData}>Inserisci</button>
								<button onClick={getData}>Mostra JSON</button>
							</div>
							<div className="image-image">
								<img
									src={reactImageUrlAdded}
									alt=""
									key={getUniSerialNumber() + reloadImg}
									onError={(img) => (img.currentTarget.src = imgPlaceHolder)}
								/>
							</div>
						</div>
						<div className="div-json-object">
							<p></p>

							<button
								onClick={() => {
									document.getElementsByClassName("div-json-object")[0].style.display = "none";
								}}
							>
								Nascondi JSON
							</button>
							<button
								onClick={() => {
									navigator.clipboard.writeText(JSON.stringify(jsonPhoto));
								}}
								className="btn-copy"
							>
								Copia
							</button>
						</div>
					</div>
					<h2>View Image ({jsonPhotoFiltered?.length})</h2>{" "}
					<span>
						<Input fnToexecute={searchFunction} datalistOptions={["prova", "albania", "praga"]} />
					</span>
					<Tab
						tabs={["grid", "card"]}
						fnOnclick={(el) => {
							setFilterOnClick(el.target.innerText.toLowerCase());
						}}
					/>
					{filterOnClick === "grid" ? (
						<div className="admin-table">
							{jsonPhotoFiltered ? (
								<table>
									<thead>
										<tr>
											<td></td>
											{tableTitle?.map((td) => {
												return <td key={td}>{`${td.charAt(0).toUpperCase() + td.slice(1)}`}</td>;
											})}
										</tr>
									</thead>
									<tbody>{loadTableContent()}</tbody>
								</table>
							) : (
								""
							)}
						</div>
					) : (
						<div className="admin-cards">{loadCardsContent()}</div>
					)}
				</div>
			</main>
			<Footer />
		</React.Fragment>
	);
};

export default AdminPanel;
