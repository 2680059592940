import React, { useEffect, useMemo, useState } from "react";

import "./index.scss";

import { Link } from "react-router-dom";

import { getUniSerialNumber, getYears, filterInPhotos } from "../../../utilities";

import DynamicText from "../../DynamicText";
// import OnScroll from "../../OnScroll";

const BarChart = (props) => {
	const { arrayPhoto, title, subtitle } = props;

	const [allYears, setAllYears] = useState([]);
	const [graphMatrix, setGraphMatrix] = useState([]);
	const [maxPhotosForYear, setMaxPhotosForYear] = useState(0);
	const [pixelForSigleUnit, setPixelForSigleUnit] = useState(5);
	const [divisionScaleNumber, setDivisionScaleNumber] = useState(5);
	const [graphId, setGraphId] = useState(`bar-scale-${getUniSerialNumber()}`);
	const [graphIsShowOnce, setGraphIsShowOnce] = useState(false);

	const setVariablePixelNumber = () => {
		if (window.innerWidth < 720) {
			setPixelForSigleUnit(3);
			setDivisionScaleNumber(10);
		} else {
			setPixelForSigleUnit(5);
			setDivisionScaleNumber(5);
		}
	};

	const isInViewport = (idElement) => {
		if (document.getElementById(idElement) && !graphIsShowOnce) {
			let element = document.getElementById(idElement);
			let elementBound = element.getBoundingClientRect();
			let html = document.documentElement;

			if (
				elementBound.top >= 0 &&
				elementBound.left >= 0 &&
				elementBound.bottom <= (window.innerHeight || html.clientHeight) &&
				elementBound.right <= (window.innerWidth || html.clientWidth)
			) {
				return element;
			} else return false;
		}
	};

	useMemo(() => {
		setAllYears(getYears(arrayPhoto));

		setVariablePixelNumber();
	}, []);

	window.onresize = () => {
		setVariablePixelNumber();
	};

	window.onscroll = () => {
		if (!graphIsShowOnce) {
			if (isInViewport(graphId)) {
				let element = isInViewport(graphId);
				element.querySelectorAll(".bar__solid").forEach((bar) => {
					bar.classList.add("bar-animation");
				});
			}
		}
	};

	useMemo(() => {
		const graphMatrix = [];
		const numberOfPhotos = [];

		allYears.map((year) => {
			const length = filterInPhotos(arrayPhoto, "year", year).length;
			graphMatrix.push([year, length]);
			numberOfPhotos.push(length);
		});

		numberOfPhotos.sort((a, b) => {
			return b - a;
		});

		setMaxPhotosForYear(numberOfPhotos[0]);

		setGraphMatrix(graphMatrix);
	}, [allYears]);

	const createScale = () => {
		const elements = [];

		for (let i = 0; i < maxPhotosForYear; i++) {
			elements.push(
				<div className="bar-scale__container" key={getUniSerialNumber()}>
					{i % divisionScaleNumber === 0 ? <div className="bar-scale__line"></div> : null}
					<div className="bar-scale__number" style={{ "--bar-single-unit-width": `${pixelForSigleUnit}px` }}>
						{i % divisionScaleNumber === 0 ? i : null}
					</div>
				</div>
			);
		}

		return elements;
	};

	return (
		<React.Fragment>
			<div className="graph-bar-chart" id={graphId}>
				<div className="graph-bar-chart__titles">
					<div className="title">{<DynamicText text={`${("Foto scattate per ogni anno").replaceAll("_", " ")}`} />}</div>
					<div className="subtitle"></div>
				</div>
				<div className="graph-bar-chart__bars">
					{graphMatrix.map((matrix, i) => {
						return (
							<div
								className="bar"
								key={getUniSerialNumber()}
								onMouseEnter={() => {
									document.getElementById(`bar_number-${i}`).style.opacity = "1";
								}}
								onMouseLeave={() => {
									document.getElementById(`bar_number-${i}`).style.opacity = "0";
								}}
							>
								<div className="bar__title">{matrix[0]}</div>
								<div
									className="bar__solid"
									style={{ "--bar-single-unit-width": `${matrix[1] * pixelForSigleUnit}px` }}
								></div>
								<div className="bar__number" id={`bar_number-${i}`}>
									{matrix[1]}
								</div>
							</div>
						);
					})}
				</div>
				<div className="bar-scale">{createScale()}</div>
			</div>
		</React.Fragment>
	);
};

export default BarChart;
