import React from "react";

import { Link } from "react-router-dom";

import "./index.scss";
import logoImg from "../../images/logo_white.png";

const Logo = (props) => {
	return (
		<React.Fragment>
			<Link to="/" className="link_logo">
				<div className="logo_image" id="logo_image">
					<img src={logoImg} alt="logo" type="png" />
				</div>
			</Link>
		</React.Fragment>
	);
};

export default Logo;
