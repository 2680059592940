import React, { useState, useMemo, useEffect } from "react";

import "./index.scss";

import { loadPhotosJson } from "../../utilities";

import Button from "../Button";
import DynamicText from "../DynamicText";

const TextCard = (props) => {
	const { filter, text, title, textPosition, subtitle, subtitleYear, url, urlDetail, noBtn = false } = props;
	const [jsonPhoto, setJsonPhoto] = useState([]);
	const [filterdPhoto, setFilterdPhoto] = useState([]);
	const [indexHorPhoto, setIndexHorPhoto] = useState(-1);

	useMemo(() => {
		const jsonPhotoOrigin = loadPhotosJson();
		setJsonPhoto(jsonPhotoOrigin.foto);
	}, []);

	useEffect(() => {
		if (jsonPhoto?.length > 0) {
			// console.log(filter);
			if (filter) {
				let filteredArray = [];

				//recupero le foto filtrandole
				jsonPhoto.map((foto) => {
					let count = 0;
					let filterNumber = Object.keys(filter).length;

					for (const [key, value] of Object.entries(filter)) {
						if (foto[key] === value) count++;
					}
					if (count === filterNumber && count > 0) filteredArray.push(foto);
				});

				//cerco un immagine orizzontale
				filteredArray.forEach((photo, i) => {
					const photoUploaded = new Image();
					photoUploaded.src = require(`../../images/photos/${photo.url}.jpg`);

					photoUploaded.onload = async () => {
						if (photoUploaded.naturalHeight < photoUploaded.naturalWidth) setIndexHorPhoto(i);
					};
				});

				//se non esistono queste foto filtrate lo inserisco nel session storage
				if (!window.sessionStorage.getItem(urlDetail))
					window.sessionStorage.setItem(urlDetail, JSON.stringify(filteredArray));

				// if (!window.sessionStorage.getItem(urlDetail + "_text"))
				// 	window.sessionStorage.setItem(urlDetail + "_text", JSON.stringify(text));
				return setFilterdPhoto(filteredArray);
			}
		}
	}, [jsonPhoto]);

	return (
		<React.Fragment>
			<div className={`text-card${textPosition === "dx" ? " text-at-dx" : ""}`}>
				<div className="text-card__text">
					<div className="text-card__title">
						<DynamicText text={title} />
					</div>
					{subtitle ? (
						<div className="text-card__subtitle">
							<DynamicText text={subtitle} />
							{` ${subtitleYear}`}
						</div>
					) : null}

					<div className="text-card__text-content">
						<DynamicText text={text} isCapitalize={true} />
					</div>

					{!noBtn ? <Button type="primary" text="scopri di più" url={`${url}`} /> : null}
				</div>
				<div className="text-card__card">
					{filterdPhoto?.length > 0 && indexHorPhoto !== -1 ? (
						<img
							src={require(`../../images/photos/${filterdPhoto[indexHorPhoto].url}.jpg`)}
							alt={filterdPhoto[indexHorPhoto].altimage}
							// onClick={() => {}}
							loading="lazy"
						/>
					) : null}
				</div>
			</div>
		</React.Fragment>
	);
};

export default TextCard;
