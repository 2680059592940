import React from "react";

import "./index.scss";

import { Link } from "react-router-dom";

import Icon from "../../components/Icon";
import DynamicText from "../../components/DynamicText";

import { getUniSerialNumber } from "../../utilities";

const BreadCrumb = ({ path = [] }) => {
	return (
		<React.Fragment>
			<div className="breadcrumb-container">
				<div className="breadcrumb__element">
					<div className="breadcrumb__icon">
						<Link to={"/"}>
							<Icon properties={{ fontSize: "24px" }} addClass={["linker"]} />
						</Link>
					</div>
					{/* <div className="breadcrumb__text"></div> */}
				</div>
				{path?.length > 0
					? path.map((text) => {
							return (
								<div className="breadcrumb__element" key={getUniSerialNumber()}>
									<div className="breadcrumb__text">
										{text.toLowerCase() !== "categorie" ? (
											<DynamicText text={text} />
										) : (
											<Link to={"/" + process.env.REACT_APP_ROUTE_CATEGORY_ALL}>
												<DynamicText text={text} />
											</Link>
										)}
									</div>
								</div>
							);
					  })
					: null}
			</div>
		</React.Fragment>
	);
};

export default BreadCrumb;
