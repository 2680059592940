import React, { useState } from "react";

import "./index.scss";

import DynamicText from "../../components/DynamicText";

import { getUniSerialNumber } from "../../utilities";

const Tab = ({ tabs, fnOnclick }) => {
	const [isActive, setIsActive] = useState(0);
	const [activeElement, setActiveElement] = useState(tabs[0]);

	const createTabs = (classBase) => {
		return tabs.map((tab, i) => {
			return (
				<div
					className={`${classBase}${i === isActive ? " active" : ""}`}
					// id={`id-tabs-${i}`}
					onClick={(el) => {
						setIsActive(i);
						setActiveElement(tab);
						if (fnOnclick) {
							// console.log(el.target.innerText.toLowerCase());
							fnOnclick(el);
						}
					}}
					key={getUniSerialNumber()}
				>
					<DynamicText text={tab} />
				</div>
			);
		});
	};

	return (
		<React.Fragment>
			<div className="tabs">{tabs?.length > 0 ? createTabs("tabs__element") : null}</div>
		</React.Fragment>
	);
};

export default Tab;
