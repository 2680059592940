import React from "react";

import "./index.scss";

import { Link } from "react-router-dom";

import DynamicText from "../DynamicText";

import { getUniSerialNumber } from "../../utilities";

//data = ["nome", "path_image", "link"]
const CardSlider = ({ data }) => {
	const idSlider = `slider-${getUniSerialNumber()}`;
	return (
		<React.Fragment>
			<div className="card-slider-container">
				<div className="slider-content" id={idSlider}>
					{data?.length > 0
						? data.map((arr, i) => {
								return (
									<Link
										to={`/${arr[2]}`}
										key={getUniSerialNumber()}
										className="card-slider"
										style={{ "--pathImgName": `url("${require(`../../images/photos/${arr[1]}.jpg`)}")` }}
										id={`card-slider-${i}`}
									>
										<span className="font-title">
											<DynamicText text={arr[0]} />
										</span>
									</Link>
								);
						  })
						: null}
				</div>

				{data?.length > 0 ? (
					<div className="slider-points">
						{data.map((arr, i) => {
							return (
								<div
									className="slider-points__point"
									key={getUniSerialNumber()}
									onClick={() => {
										const slider = document.getElementById(idSlider);
										let cardWidth = document.getElementById(`card-slider-${i}`).getBoundingClientRect().width;

										//sposto la visualizzazione in base alla grandezza della card per la sua posizione
										slider.scrollTo({ left: cardWidth * i, behavior: "smooth" });
									}}
								></div>
							);
						})}
					</div>
				) : null}
			</div>
		</React.Fragment>
	);
};

export default CardSlider;
