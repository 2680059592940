import React from "react";

import "./index.scss";

import Icon from "../Icon";

import { getUniSerialNumber } from "../../utilities";

const Input = ({
	placeholder = "search",
	fnToexecute = () => {},
	fnSecondToExecute = () => {},
	datalistOptions,
	isDark = false,
}) => {
	const uniqNumberId = `id-search-${getUniSerialNumber()}`;

	const execExternalFunction = () => {
		const input = document.getElementById(uniqNumberId);

		if (fnSecondToExecute) fnSecondToExecute();
		return fnToexecute(input.value);
	};

	return (
		<React.Fragment>
			<div className={`input-container ${isDark ? "input-container--dark" : ""}`}>
				<Icon name={"search"} addClass={["search-icon"]} onClickFn={execExternalFunction} />
				<input
					className="search-input"
					placeholder={placeholder}
					id={uniqNumberId}
					list="search-input-datalist"
					onKeyUp={(el) => {
						//attivo il trigger per il bottone enter
						if (el.key === "Enter") execExternalFunction();
					}}
				/>
				{datalistOptions?.length > 0 ? (
					<datalist id="search-input-datalist">
						{datalistOptions.map((data, i) => {
							return <option key={data + getUniSerialNumber()} value={data} />;
						})}
					</datalist>
				) : null}
			</div>
		</React.Fragment>
	);
};

export default Input;
