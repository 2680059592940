import PolicyBanner from "../components/PolicyBanner";

const execGetQuery = async (route, params = {}) => {
	// console.log(sessionStorage);

	try {
		if (sessionStorage.getItem(route)) {
			// console.log("Recupero dati");
			//converto i dati json in oggetto
			return JSON.parse(sessionStorage.getItem(route));
		} else {
			// console.log("Eseguo Query");
			const response = await fetch(`${process.env.REACT_APP_API_URL}/${route}`, {
				params,
			});

			if (!response.ok) {
				handleError(response, `on execGetQuery route: ${route}`);
				return;
			}

			const result = await response.json();
			//converto in json l'oggetto per salvarlo
			sessionStorage.setItem(route, JSON.stringify(result));
			return result;
		}
	} catch (error) {
		console.error(error);
	}
};

const execPostQuery = async (route, object = {}) => {
	const response = await fetch(`${process.env.REACT_APP_API_URL}/${route}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(object),
	}).catch((error) => {
		window.alert(error);
		return;
	});
	if (!response.ok) {
		handleError(response, `on execPostQuery route: ${route}`);
		return response;
	} else {
		return response;
	}
};

const handleError = (response) => {
	const message = `An error occurred: ${response.statusText}`;
	window.alert(message);
};

const getRandomWithRange = (max) => {
	return Math.floor(Math.random() * max);
};

//recupero il json delle traduzioni
const loadLenguage = () => {
	try {
		const jsonTraduction = require("../json/traduction.json");

		//ricerco nel session storage se è stata settata la lingua
		if (!sessionStorage.getItem("leng")) {
			Object.keys(jsonTraduction).map((key) => {
				if (key === navigator.language.slice(0, 2)) sessionStorage.setItem("leng", navigator.language.slice(0, 2));
				else sessionStorage.setItem("leng", "it");
			});
		}
		return jsonTraduction;
	} catch (error) {
		return console.error(error);
	}
};

const loadPhotosJson = (forceLoad = false) => {
	try {
		//recupero i dati della memoria
		let sessionJson = window.sessionStorage.getItem("original-photo-json");

		//se esistono uso quelli, altrimenti li recupero
		if (sessionJson && forceLoad === false) {
			return JSON.parse(sessionJson);
		} else {
			const photoJson = require("../json/photos.json");
			window.sessionStorage.setItem("original-photo-json", JSON.stringify(photoJson));
			return photoJson;
		}
	} catch (error) {
		return console.error(error);
	}
};

const getUniSerialNumber = () => {
	//numero seriale salvato nel session
	const sessionSerialNumber = parseInt(window.sessionStorage.getItem("uni-serial-number"));

	//se esiste lo aggiorno e lo uso
	if (sessionSerialNumber >= 0) {
		//setto il numero seriale delle card
		setCardSerialNumber(sessionSerialNumber + 1);
		//ritorno il valore
		return sessionSerialNumber + 1;
	} else {
		setCardSerialNumber(0);
		return 0;
	}
};

const setCardSerialNumber = (serialNumberToSet) => {
	if (serialNumberToSet === 0) {
		window.sessionStorage.setItem("uni-serial-number", 0);
	} else {
		window.sessionStorage.setItem("uni-serial-number", serialNumberToSet);
	}
};

//cerca nell'array uno specifico filtro
const filterInPhotos = (array, toSearch, filter, filterIsArray = false) => {
	if (!filterIsArray && !Array.isArray(filter)) {
		return array.filter((photo) => {
			return photo[toSearch] === filter ? photo : null;
		});
	} else {
		const newArray = filter.map((fil) => {
			return array.filter((photo) => {
				// console.log(photo[toSearch].includes(fil) ? photo : "");
				return photo[toSearch].includes(fil) ? photo : null;
			});
		});
		// console.log();
		if (Array.isArray(newArray[0])) return newArray[0];
		else return newArray;
	}
};

const getCategories = (photos) => {
	let categories = [];
	photos.map((photo) => {
		if (!categories.includes(photo.category)) categories.push(photo.category);
		return;
	});

	return categories;
};
const getYears = (photos) => {
	let years = [];
	photos.map((photo) => {
		if (!years.includes(photo.year)) years.push(photo.year);
		return;
	});

	return years.sort();
};

//filtro tutti i filtri che mi vengono passati (es: city=lodi&year=2022-2024)
//al momento sono filtri multipli li gestisco come un OR
const photoFilter = (photos, filterKey, filterValue = "", multipleValue = []) => {
	//se non sono filtri multipli
	if (multipleValue.length === 0) {
		//se non sono i tags
		if (filterKey !== "tags") {
			return photos.filter((photo) => {
				if (String(photo[filterKey]) === String(filterValue)) return photo;
			});
		} else {
			//se sono tags
			return photos.filter((photo) => {
				if (photo[filterKey].includes(filterValue)) return photo;
			});
		}
	} else {
		//se sono filtri multipli
		let newArrayFiltered = [];

		photos.filter((photo) => {
			multipleValue.forEach((value) => {
				if (filterKey !== "tags") {
					if (String(photo[filterKey]) === String(value)) return newArrayFiltered.push(photo);
				} else {
					//se sono tags
					if (photo[filterKey].includes(value)) return newArrayFiltered.push(photo);
				}
			});
		});
		return newArrayFiltered;
	}
};

export {
	handleError,
	execGetQuery,
	execPostQuery,
	getRandomWithRange,
	loadLenguage,
	loadPhotosJson,
	getUniSerialNumber,
	filterInPhotos,
	getCategories,
	getYears,
	photoFilter,
};
