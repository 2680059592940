import React, { useMemo, useState } from "react";

import { Link } from "react-router-dom";

import "./index.scss";

import Logo from "../Logo";
import DynamicText from "../../components/DynamicText";
import Icon from "../../components/Icon";
import Input from "../../components/Input";
import { loadLenguage } from "../../utilities";

const Header = ({ search = false, fnToexecute = () => {} }) => {
	// const [navLinkOpacity, setNavLinkOpacity] = useState(0);
	const [lenguages, setlenguages] = useState([]);

	useMemo(() => {
		setlenguages(Object.keys(loadLenguage()));
	}, []);

	//dato il chiamante controlla che se l'altro menù è aperto lo chiude
	const checkMenu = (caller) => {
		if (caller === "nav") {
			const settingLink = document.getElementById("setting_link");

			//se è aperto lo chiudo
			if (settingLink.classList.contains("open")) toggleNavSettings();
		} else {
			const navLink = document.getElementById("nav_link");

			//se è aperto lo chiudo
			if (navLink.classList.contains("open")) toggleNavMenu();
		}
	};

	const toggleNavMenu = () => {
		const menuOpener = document.getElementById("menu_opener");
		const navLink = document.getElementById("nav_link");

		//verifico che non sia aperto l'altro menu, nel caso lo chiudo
		checkMenu("nav");

		menuOpener.classList.toggle("open");
		navLink.classList.toggle("open");
	};

	const toggleNavSettings = () => {
		const settingOpener = document.getElementById("menu_settings");
		const settingLink = document.getElementById("setting_link");

		//verifico che non sia aperto l'altro menu, nel caso lo chiudo
		checkMenu("settings");

		settingOpener.classList.toggle("open");
		settingLink.classList.toggle("open");
	};

	const changeLeng = (leng) => {
		sessionStorage.setItem("leng", leng);
	};

	const loadSettings = (caller) => {
		return lenguages.map((leng) => {
			return (
				<button
					value={leng}
					key={leng}
					onClick={(el) => {
						//cambio la lingua
						changeLeng(el.currentTarget.value);
						//ricarico la pagina
						window.location.reload();
					}}
					className={`${caller} ${sessionStorage.getItem("leng") === leng ? "selected " : ""}${leng}`}
				>
					{leng === "it" ? "Italiano" : leng === "es" ? "Español" : leng === "en" ? "English" : leng}
				</button>
			);
		});
	};

	return (
		<React.Fragment>
			<header id="header">
				<Logo />
				<div id="menu_opener" onClick={toggleNavMenu}>
					<div className="menu_opener_line" id="line1"></div>
					<div className="menu_opener_line" id="line2"></div>
				</div>
				<div id="menu_settings" onClick={toggleNavSettings}>
					<Icon name="settings" properties={{ fontSize: "30px" }} />
				</div>
				<div id="setting_link" className="header-modal">
					{search ? (
						<>
							<Input
								isDark={true}
								fnToexecute={fnToexecute}
								fnSecondToExecute={toggleNavSettings}
								placeholder="cerca nella pagina"
							/>
							<hr />
						</>
					) : null}

					<div className="header-modal__title">
						<DynamicText text="Cambia lingua" />
					</div>
					{lenguages?.length > 0 ? loadSettings("leng-settings-buttons") : null}
				</div>
				<div id="nav_link" className="header-modal">
					<div className="header-modal__title">
						<DynamicText text="Categorie" />
					</div>
					<Link to={`/${process.env.REACT_APP_ROUTE_CATEGORY_ALL}`} onClick={toggleNavMenu}>
						<DynamicText text="tutte" />
					</Link>
					<Link to={`/${process.env.REACT_APP_ROUTE_PHOTO_NATURA}`} onClick={toggleNavMenu}>
						<DynamicText text="Natura" />
					</Link>
					<Link to={`/${process.env.REACT_APP_ROUTE_PHOTO_PERSONE}`} onClick={toggleNavMenu}>
						<DynamicText text="Street & Persone" />
					</Link>
					<Link to={`/${process.env.REACT_APP_ROUTE_PHOTO_VARIE}`} onClick={toggleNavMenu}>
						<DynamicText text="varie" />
					</Link>
					<Link to={`/${process.env.REACT_APP_ROUTE_PHOTO_PAESAGGI}`} onClick={toggleNavMenu}>
						<DynamicText text="Paesaggi" />
					</Link>
					<hr />
					<div className="header-modal__title">
						<DynamicText text="Città" />
					</div>
					<Link to={`/${process.env.REACT_APP_ROUTE_PHOTO_CITY_ROMA}`} onClick={toggleNavMenu}>
						<DynamicText text="roma" />
					</Link>
					<Link to={`/${process.env.REACT_APP_ROUTE_PHOTO_CITY_VENEZIA}`} onClick={toggleNavMenu}>
						<DynamicText text="venezia" />
					</Link>
					<Link to={`/${process.env.REACT_APP_ROUTE_PHOTO_CITY_TORINO}`} onClick={toggleNavMenu}>
						<DynamicText text="torino" />
					</Link>
					<Link to={`/${process.env.REACT_APP_ROUTE_PHOTO_CITY_LODI}`} onClick={toggleNavMenu}>
						<DynamicText text="lodi" />
					</Link>
					<hr />
					<div className="header-modal__title">
						<DynamicText text="Link utili" />
					</div>
					<Link to={`/${process.env.REACT_APP_ROUTE_ABOUT_ME}`} onClick={toggleNavMenu}>
						<DynamicText text="About me" />
					</Link>
					<hr className="leng-buttons" />
					<div className="header-modal__title leng-buttons">
						<DynamicText text="Cambia lingua" />
					</div>

					{lenguages?.length > 0 ? loadSettings("leng-buttons") : null}
				</div>
			</header>
		</React.Fragment>
	);
};

/**
 * 							

 */

export default Header;
