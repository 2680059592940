import React from "react";

import "./index.scss";

const Icon = ({ name, properties = {}, addClass = [], fill = true, subtitle, onClickFn = () => {} }) => {
	return (
		<React.Fragment>
			<div className="icon-component">
				<span
					className={`material-symbols-outlined icon${addClass.length > 0 ? " " + addClass.join(" ") : ""}${
						fill === false ? " no-fill" : ""
					}`}
					style={Object.keys(properties).length > 0 ? properties : {}}
					onClick={onClickFn ? onClickFn : null}
				>
					{name}
				</span>
				{subtitle ? <div className="icon-component__subtitle">{subtitle}</div> : null}
			</div>
		</React.Fragment>
	);
};

export default Icon;
