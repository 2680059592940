import React from "react";

import "./index.scss";

import DynamicText from "../../components/DynamicText";

const Counter = ({ number, text, color }) => {
	return (
		<React.Fragment>
			<div className={`counter-container counter-container--${color}`}>
				<div className="counter-container__circle">{number}</div>
				<div className="counter-container__text">
					<DynamicText text={text} isCapitalize={true} />
				</div>
			</div>
		</React.Fragment>
	);
};

export default Counter;
