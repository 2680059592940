import React, { useState } from "react";

import "./index.scss";
import "../../scss/main.scss";

import DynamicText from "../../components/DynamicText";

const Masonry = (props) => {
	const { photos } = props;

	const [modalImg, setModalImg] = useState();
	const [data, setData] = useState({
		body: {
			height: document.body.style.height,
			overflow: document.body.style.overflow,
		},
		footer: {
			zIndex: document.getElementById("footer")?.style.zIndex,
		},
	});

	const openPhotoModal = () => {
		const modalElement = document.getElementById("photoModal");

		modalElement.style.opacity = 1;
		modalElement.style.visibility = "visible";

		document.body.style.height = "100%";
		document.body.style.overflow = "hidden";

		document.getElementById("footer").style.zIndex = 0;
	};

	const closeModal = () => {
		const modalElement = document.getElementById("photoModal");

		modalElement.style.opacity = 0;
		modalElement.style.visibility = "hidden";

		document.body.style.height = data.body.height;
		document.body.style.overflow = data.body.overflow;

		document.getElementById("footer").style.zIndex = data.footer.zIndex;

		setModalImg("");
	};

	return (
		<React.Fragment>
			<div className="masonry-grid">
				{photos?.length > 0
					? photos.map((photo) => {
							return (
								<div className="masonry-card" key={photo.url}>
									<img
										src={require(`../../images/photos/${photo.url}.jpg`)}
										alt={photo.altimage}
										// onClick={() => {
										// 	setModalImg(photo);
										// 	openPhotoModal();
										// }}
										loading="lazy"
									/>
									<div className="masonry-card__tags">
										<DynamicText text={photo.city} />, <DynamicText text={photo.state.replace("_", " ")} />
									</div>
								</div>
							);
					  })
					: null}

				<div id="photoModal" onClick={closeModal}>
					{modalImg ? (
						<img
							src={require(`../../images/photos/${modalImg.url}.jpg`)}
							alt={modalImg.altimage}
							id="modalImg"
							onLoad={(image) => {
								//rimuovo tutte le classi dall'immagine
								image.target.removeAttribute("class");

								//inserisco la classe corretta in base all'altezza e larghezza
								if (image.target.offsetWidth > image.target.offsetHeight) image.target.classList.add("hor-img");
								else image.target.classList.add("ver-img");
							}}
						/>
					) : (
						""
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default Masonry;
