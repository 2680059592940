import React, { useState, useMemo, useEffect } from "react";

//routing
import { Link } from "react-router-dom";

import "./index.scss";
import "../../scss/main.scss";

import {
	loadLenguage,
	getCategories,
	loadPhotosJson,
	filterInPhotos,
	getUniSerialNumber,
	getRandomWithRange,
} from "../../utilities";

import Header from "../../components/Header";
import DynamicText from "../../components/DynamicText";
import TextCard from "../../components/TextCard";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import CardSlider from "../../components/CardSlider";

const Home = (props) => {
	const [filteredCategories, getFilteredCategories] = useState([]);
	const [jsonPhoto, setJsonPhoto] = useState([]);
	const [showFirstAnimation, setShowFirstAnimation] = useState(false);
	// const [ringOnclick, getRingOnclick] = useState("");

	const romaText =
		"Roma, un museo a cielo aperto, offre infinite opportunità fotografiche. Dai vicoli pittoreschi di Trastevere al maestoso Colosseo, ogni angolo racconta una storia. Le luci calde del tramonto trasformano la città in uno spettacolo visivo, perfetto per catturare scatti unici e indimenticabili";
	const torinoText =
		"Torino, elegante e affascinante, offre scorci fotografici unici. Le maestose piazze, i portici storici e la Mole Antonelliana creano un mix perfetto di storia e modernità. Le Alpi sullo sfondo e il Parco del Valentino aggiungono un tocco naturale, rendendo ogni scatto un racconto visivo di bellezza e cultura";
	const veneziaText =
		"Venezia, con i suoi canali sinuosi e palazzi storici, è un paradiso per i fotografi. Le gondole che scivolano sull'acqua, i riflessi dorati al tramonto e le maschere del Carnevale offrono scatti mozzafiato. Ogni angolo della città evoca magia e romanticismo, rendendo ogni foto un capolavoro";
	const valDOrciaText =
		"La Val d’Orcia, con le sue colline ondulate e i cipressi solitari, è un sogno per i fotografi. I borghi medievali, i vigneti e i campi dorati offrono scenari idilliaci in ogni stagione. Qui, la luce morbida al tramonto e all’alba trasforma ogni angolo in un dipinto da catturare";

	useMemo(() => {
		window.scrollTo(0, 0);

		if (!window.sessionStorage.getItem("first-animation")) {
			window.sessionStorage.setItem("first-animation", false);
		} else {
			setShowFirstAnimation(true);
		}

		//TODO: if nav open and click on main close

		// getCategories(filterInPhotos(loadPhotosJson().foto, "category", "natura"));

		//recupero tutte le foto
		// getCategories(getFilteredCategories(loadPhotosJson().foto));
		// console.log(loadPhotosJson().foto);
		// console.log(getCategories(loadPhotosJson().foto));

		const jsonPhotoOrigin = loadPhotosJson();
		getFilteredCategories(getCategories(jsonPhotoOrigin.foto));
		setJsonPhoto(jsonPhotoOrigin);

		//recupero il json delle traduzioni
		loadLenguage();

		//rimuovo gli eventuali attributi inline dal body
		if (document.body.getAttribute("style")) document.body.removeAttribute("style");

		//imposto il nome della pagina correttamente
		document.title = process.env.REACT_APP_APP_NAME_EXTENDED;
	}, []);

	const timeout = (delay) => {
		return new Promise((res) => setTimeout(res, delay));
	};

	useEffect(() => {
		if (!showFirstAnimation)
			(async () => {
				await timeout(2600);

				const grid = document.getElementsByClassName("photography-grid")[0];
				if (grid) {
					grid.remove();
				}
				// }
			})();
	}, [showFirstAnimation]);

	return (
		<React.Fragment>
			<Header />
			<main id="home-main">
				{jsonPhoto?.foto?.length > 0 && !showFirstAnimation ? (
					<div className="photography-grid">
						{/*Fare controllo sui numeri random*/}
						{jsonPhoto.foto.map((photo, i) => {
							if (i <= 8) {
								let numberRandom = getRandomWithRange(jsonPhoto.foto.length);

								return (
									<span className={`photography-grid__${i + 1}`} key={getUniSerialNumber()}>
										<img
											src={require(`../../images/photos/${jsonPhoto.foto[numberRandom].url}.jpg`)}
											alt={jsonPhoto.foto[numberRandom].altimage}
											loading="lazy"
										/>
									</span>
								);
							} else {
								return null;
							}
						})}
					</div>
				) : null}

				<div className="home-main__bg"></div>

				<div className="home-main__text">
					<div className="home-main__text__title font-title">
						Riccardo <span>Biffi</span>
					</div>
					<div className="home-main__text__subtitle">
						<span>
							<DynamicText text={"fotografia"} />
						</span>
						<span></span>
					</div>
					<div className="home-main__text__discover">
						<Button
							type="primary"
							text="esplora"
							onclickFunction={() => {
								document.getElementsByClassName("home-main__content")[0].scrollIntoView();
							}}
						/>
					</div>
				</div>
				<div className="home-main__content">
					<div className="content-item content-item__categories centered">
						<div className="section-title font-title" id="first-title">
							<DynamicText text={"Categorie pricipali"} />
						</div>
						{jsonPhoto?.foto?.length > 0 ? (
							<div className="cards content-item__content">
								<div className="single-card">
									<Link to={`/${process.env.REACT_APP_ROUTE_PHOTO_NATURA}`}>
										<div className="single-card__text">
											<div className="single-card__title">
												<DynamicText text={"Natura"} />
											</div>
											<div className="single-card__subtitle">
												<DynamicText text={"scopri la natura"} />
											</div>
										</div>
									</Link>
									<img
										src={require(`../../images/photos/2021_it_parcoFaunistisco4_bergamo.jpg`)}
										className="photo-frame__image-container"
										key={getUniSerialNumber()}
										alt={jsonPhoto.foto[13].altimage}
										loading="lazy"
									/>
								</div>
								<div className="single-card">
									<Link to={`/${process.env.REACT_APP_ROUTE_PHOTO_PERSONE}`}>
										<div className="single-card__text">
											<div className="single-card__title">
												<DynamicText text={"Street & Persone"} />
											</div>
											<div className="single-card__subtitle">
												<DynamicText text={"fatti raccontare una storia"} />
											</div>
										</div>
									</Link>
									<img
										src={require(`../../images/photos/2022_it_ritrattoRagazza_bergamo.jpg`)}
										className="photo-frame__image-container"
										key={getUniSerialNumber()}
										alt={jsonPhoto.foto[13].altimage}
										loading="lazy"
									/>
								</div>
								<div className="single-card">
									<Link to={`/${process.env.REACT_APP_ROUTE_PHOTO_PAESAGGI}`}>
										<div className="single-card__text">
											<div className="single-card__title">
												<DynamicText text={"Paesaggi"} />
											</div>
											<div className="single-card__subtitle">
												<DynamicText text={"perditi in ogni luogo"} />
											</div>
										</div>
									</Link>
									<img
										src={require(`../../images/photos/2024_it_workshop4_venezia.jpg`)}
										className="photo-frame__image-container"
										key={getUniSerialNumber()}
										alt={jsonPhoto.foto[13].altimage}
										loading="lazy"
									/>
								</div>
								<div className="single-card">
									<Link to={`/${process.env.REACT_APP_ROUTE_PHOTO_VARIE}`}>
										<div className="single-card__text">
											<div className="single-card__title">
												<DynamicText text={"Varie"} />
											</div>
											<div className="single-card__subtitle">
												<DynamicText text={"ogni cosa ha il suo posto"} />
											</div>
										</div>
									</Link>
									<img
										src={require(`../../images/photos/2024_it_statua3_firenze.jpg`)}
										className="photo-frame__image-container"
										key={getUniSerialNumber()}
										alt={jsonPhoto.foto[13].altimage}
										loading="lazy"
									/>
								</div>
							</div>
						) : null}
					</div>
					<div className="content-item centered">
						<div className="section-title font-title">
							<DynamicText text={"Altre categorie"} />
						</div>
						<div className="content-item__content">
							<CardSlider
								data={[
									["vedi tutte", "2023_it_aiuola1_pavia", process.env.REACT_APP_ROUTE_CATEGORY_ALL],
									["italia", "2024_it_valDOrcia8_siena", process.env.REACT_APP_ROUTE_PHOTO_ITALIA],
									["lodi", "2019_it_mongolfiera_lodi", process.env.REACT_APP_ROUTE_PHOTO_CITY_LODI],
									["animali", "2021_it_parcoFaunistisco9_bergamo", process.env.REACT_APP_ROUTE_PHOTO_ANIMALI],
									["piante", "2022_it_pianta1_pavia", process.env.REACT_APP_ROUTE_PHOTO_VEGETALI],
									["bianco e nero", "2022_it_ponte_venezia", process.env.REACT_APP_ROUTE_PHOTO_BIANCONERO],
									["mare", "2024_it_workshop4_venezia", process.env.REACT_APP_ROUTE_PHOTO_MARE],
									["ritratti", "2018_it_ritrattoCampagnaBF2_lodi", process.env.REACT_APP_ROUTE_PHOTO_RITRATTI],
									["statue", "2022_it_scultura_roma", process.env.REACT_APP_ROUTE_PHOTO_STATUE],
									["montagna", "2023_it_valDiSusa2_torino", process.env.REACT_APP_ROUTE_PHOTO_MONTAGNE],
								]}
							/>
						</div>
					</div>

					<div className="content-item centered">
						<div className="section-title font-title">
							<DynamicText text={"Ultimi viaggi"} />
						</div>
						<div className="content-item__content">
							<TextCard
								textPosition="dx"
								filter={{ city: "siena", year: 2024 }}
								text={valDOrciaText}
								title={"Val D'Orcia"}
								subtitle={"maggio"}
								subtitleYear={"2024"}
								urlDetail={`siena&2024`}
								url={process.env.REACT_APP_ROUTE_PHOTO_CITY_VALDORCIA}
							/>
						</div>
						<div className="content-item__content">
							<TextCard
								filter={{ city: "venezia", year: 2024 }}
								text={veneziaText}
								title={"Venezia"}
								subtitle={"Aprile"}
								subtitleYear={"2024"}
								urlDetail={`venezia&2024`}
								url={"/photos/city=venezia&year=2024&title=la-serenissima"}
							/>
						</div>
						<div className="content-item__content">
							<TextCard
								textPosition="dx"
								filter={{ city: "torino", year: 2024 }}
								text={torinoText}
								title={"Torino"}
								subtitle={"Gennaio"}
								subtitleYear={"2024"}
								urlDetail={`torino&2024`}
								url={"/photos/city=torino&year=2024&title=la-citta-dei-quattro-fiumi"}
							/>
						</div>
						<div className="content-item__content">
							<TextCard
								filter={{ city: "roma", year: 2023 }}
								text={romaText}
								title={"Roma"}
								subtitle={"Marzo"}
								subtitleYear={"2023"}
								urlDetail={`roma&2023`}
								url={"/photos/city=roma&year=2023&title=la-citta-eterna"}
							/>
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</React.Fragment>
	);
};

export default Home;
