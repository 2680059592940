import React, { useState, useMemo } from "react";

import "./index.scss";

import Button from "../Button";

const PolicyBanner = () => {
	const [isClosed, setIsClosed] = useState();
	const readTexts = ["leggi di più", "mostra meno"];
	const [readMoreText, setReadMoreText] = useState(readTexts[0]);

	useMemo(() => {
		if (!window.sessionStorage.getItem("policy-banner-is-read"))
			window.sessionStorage.setItem("policy-banner-is-read", false);

		setIsClosed(window.sessionStorage.getItem("policy-banner-is-read") === "true" ? true : false);
	}, []);

	return (
		<React.Fragment>
			{!isClosed ? (
				<div className="policy-banner">
					<div className="policy-banner__title">Informativa sulla Privacy</div>
					<div className="policy-banner__text">
						<p>
							Il nostro sito web non utilizza cookies di alcun tipo. Tuttavia, per migliorare la tua esperienza di
							navigazione, utilizziamo il session storage del tuo browser. I dati memorizzati tramite session storage
							sono temporanei e vengono automaticamente cancellati dal browser non appena la finestra o la scheda del
							browser viene chiusa.
						</p>
						<p>
							Vogliamo rassicurarti che non vengono salvati né raccolti in alcun modo dati personali o identificativi
							dell'utente. La tua privacy è la nostra priorità.
						</p>{" "}
						<p>
							Se hai ulteriori domande o dubbi, non esitare a contattarci:
							<br />
							<a href="mailto:riccardo.biffi@riccardobiffi.eu?subject=Informazioni privacy">
								riccardo.biffi@riccardobiffi.eu
							</a>
						</p>
					</div>
					<div
						className="text__read-more"
						onClick={() => {
							document.getElementsByClassName("policy-banner__text")[0].classList.toggle("expand");
							if (readMoreText === readTexts[0]) setReadMoreText(readTexts[1]);
							else setReadMoreText(readTexts[0]);
						}}
					>
						{readMoreText}
					</div>
					<div className="policy-banner__btn">
						<Button
							type="secondary"
							text="chiudi"
							onclickFunction={() => {
								window.sessionStorage.setItem("policy-banner-is-read", true);
								setIsClosed(true);
							}}
						/>
					</div>
				</div>
			) : null}
		</React.Fragment>
	);
};

export default PolicyBanner;
